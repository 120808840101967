body {
  margin: 0;
  font-family: "Press Start 2P", monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDialogContent-root .MuiList-root {
  background: #fff !important;
}
.MuiDialogContent-root .MuiListItem-root {
  border-bottom: 1px solid lightgray;
}

.MuiDialogTitle-root {
  background-color: #efc8df !important;
}

.MuiIconButton-root .MuiSvgIcon-root {
  fill: white;
}

.MuiButton-containedPrimary {
  background-color: #efc8df !important;
}
